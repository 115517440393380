import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import axios from 'axios';
import { baseClasses, variants } from '../components/layout/button';
import Layout from '../components/layout';

const Automata = () => {
  const [barionLink, setBarionLink] = useState('');

  const initialValues = {
    amount: '2000',
    name: '',
    email: '',
    phone: '',
    message: '',
    agreeDonation: '',
    newsletter: ''
  };

  const onSubmit = ({ name, email, phone, amount, message }) => {
    axios
      .post('/api/pay', { name, phone, email, amount, message })
      .then(({ data }) => {
        setBarionLink(data.GatewayUrl);
      });
  };

  const CheckOut = Yup.object().shape({
    amount: Yup.number()
      .min(1000, 'Too Short!')
      .required('Kötelező kitölteni!'),
    name: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Kötelező kitölteni!'),
    email: Yup.string()
      .min(5, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Kötelező kitölteni!'),
    phone: Yup.number().required('Kötelező kitölteni!'),
    message: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!'),
    agreeDonation: Yup.string().required('Kötelező kitölteni!'),
    newsletter: ''
  });

  return (
    <Layout
      seo={{
        title: 'Főoldal',
        description:
          'Sikerélményt viszünk a fiatalok életébe szociális helyzettől függetlenül.'
      }}
    >
      <div
        className="appy mt-20"
        style={{
          backgroundImage: 'url(/kartya2.jpg)',
          // backgroundAttachment: 'fixed',
          backgroundSize: 'cover',
          backgroundPosition: 'top',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <div className="container pt-10 mb-10">
          <div className="lg:pl-32 md:pt-16">
            <img
              alt="Adatvédelem"
              src="/appy.png"
              className="ml-3 mb-5"
              width="150"
            />{' '}
            <h1 className="mb-5 max-w-md text-2xl md:text-3xl lg:text-4xl p-3 text-coraldark">
              Köszöntünk az adományozás appycentrumában, ahol a jócselekedet
              automatikus.
            </h1>
          </div>
        </div>
      </div>
      <div className="container">
        {barionLink ? (
          <div className="flex flex-col items-center mt-10 mb-10">
            <p className="mx-auto text-center mb-3">
              A kényelmes és biztonságos online fizetést a Barion Payment Zrt.
              biztosítja.
            </p>
            <p className="mx-auto text-center mb-3">
              MNB engedély száma: H-EN-I-1064/2013 Bankkártya adatai
              weboldalunkhoz nem jutnak el
            </p>
            <p className="mx-auto text-center mb-3">
              Barion Pixel Alap Verziója csalásmegelőzési célból beépítésre
              került az elfogadóhelybe
            </p>
            <img alt="Barion" src="/logo.png" className="inline" />
            <a
              href={barionLink}
              target="_blank"
              rel="noopener noreferrer"
              className="mt-8 text-center tracking-wider font-bold uppercase py-4 px-10 border-2 border-primary rounded-full inline-block m-4 min-w-64 mx-auto hover:shadow-xl transition duration-200 ease-in-out transform hover:-translate-y-1 bg-primary text-white hover:bg-primary"
            >
              Tovább a Barion oldalára{' '}
            </a>{' '}
          </div>
        ) : (
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={CheckOut}
          >
            <Form className="ml-5 mr-5 mt-5 md:flex md:flex-row">
              <div className="md:w-1/2">
                <h2 className="text-center mt-20">Adomány</h2>
                <p className="m-5 p-3 md:px-20">
                  Ezen az oldalon segítheted, hogy egy hátrányos helyzetű fiatal
                  sikerélményben részesüljön, ehhez elegendő 2000 Ft támogatás
                  az Alapítványunk részére.
                </p>
              </div>
              <div className="mx-auto">
                <div className="max-w-lg mx-auto">
                  <div className="m-5">
                    <label
                      htmlFor="name"
                      className="text-sm font-semibold mb-2"
                    >
                      Név
                    </label>
                    <Field
                      name="name"
                      id="name"
                      type="text"
                      // placeholder="Gipsz Jakab"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className=" text-red-700 p-1"
                    />
                  </div>
                  <div className="m-5 ">
                    <label
                      htmlFor="email"
                      className="text-sm font-semibold mb-2"
                    >
                      E-mail cím
                    </label>
                    <Field
                      id="email"
                      name="email"
                      // placeholder="jakab@gamil.com"
                      type="text"
                    />

                    <ErrorMessage
                      name="email"
                      component="div"
                      className=" text-red-700 p-1"
                    />
                  </div>
                  <div className="m-5">
                    <label
                      htmlFor="phone"
                      className="text-sm font-semibold mb-2"
                    >
                      Telefonszám
                    </label>
                    <Field
                      name="phone"
                      id="phone"
                      // placeholder="+36 30 123 4567"
                      type="text"
                    />

                    <ErrorMessage
                      name="phone"
                      component="div"
                      className=" text-red-700 p-1"
                    />
                  </div>
                </div>

                <div className="max-w-lg mx-auto">
                  <div className="m-5">
                    <label
                      htmlFor="amount"
                      className="text-sm font-semibold mb-2"
                    >
                      Összeg
                    </label>
                    <Field
                      name="amount"
                      id="amount"
                      type="text"
                      disabled
                      // placeholder="Gipsz"
                    />

                    <ErrorMessage
                      name="amount"
                      component="div"
                      className=" text-red-700 p-1"
                    />
                  </div>
                </div>

                <div className="max-w-lg mx-auto">
                  <div className="m-5">
                    <label
                      htmlFor="name"
                      className="text-sm font-semibold mb-2"
                    >
                      Üzenet
                    </label>
                    <Field
                      name="message"
                      id="messager"
                      // placeholder="Ide írja üzenetét"
                      className="border border-gray-500 rounded-md p-2 w-full"
                      rows="4"
                      component="textarea"
                    />
                    <ErrorMessage
                      name="message"
                      component="div"
                      className=" text-red-700 p-1"
                    />
                  </div>
                </div>
                <div className="m-5 max-w-md mx-auto px-1">
                  <Field type="checkbox" name="agreeDonation" />
                  <ErrorMessage
                    name="agreeDonation"
                    component="div"
                    className=" text-red-700 p-1"
                  />
                  <p className="inline ml-2 ">
                    Elolvastam az{' '}
                    <a
                      href="/Adomanyozas.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="pt-4 hover:underline text-orange-500"
                    >
                      Adományozási feltételeket
                    </a>{' '}
                    és az{' '}
                    <a
                      href="/FsA_Adatvédelmi_nyilatkozat.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="pt-4 hover:underline text-orange-500"
                    >
                      Adatvédelmi tájékoztatót
                    </a>
                    , és hozzájárulok személyes adataim kezeléséhez.
                  </p>
                </div>
                <div className="m-5 max-w-md mx-auto px-1">
                  <Field type="checkbox" name="newsletter" />
                  <ErrorMessage name="newsletter" component="div" />

                  <p className="inline ml-2">Feliratkozom a hírlevélre.</p>
                </div>
                <div className="pb-16 text-center">
                  <button
                    type="submit"
                    className={clsx(baseClasses, variants.primary)}
                  >
                    Tovább a fizetéshez
                  </button>
                </div>
              </div>
            </Form>
          </Formik>
        )}
      </div>
    </Layout>
  );
};

export default Automata;
